.tableHeader {
  background-color: var(--primary-color) !important;
  font-size: 16px !important;
  font-weight: 600 !important;
}
.tabletitle {
  font-size: 32px !important;
  font-weight: 600 !important;
}

th {
  &.column {
    font-weight: 600 !important;
  }
}

td {
  &.column {
    cursor: pointer;
  }
}

.inviteButton {
  margin: 10px 0;
  font-weight: 600;
}
.buttongap{
  display: flex;
  gap: 10px;
}
